var CineplexStoreGlobalApp = window.CineplexStoreGlobalApp || {};
$(function () {
  ko.applyBindings(storeApp.mainVM(ko, $, Cineplex.Connect, moment, bowser, csTracking));

  //Initialize image lazy loading in page
  $(document).ready(function () {
    // Added to fetch user subscription flag
    //$.get('/Home/GetUserProfileLocally', {})
    //    .done((result) => { window.subscriptionVM = { IsCineClubMember: result.IsCineClubMember }; })
    //    .fail((error) => { window.subscriptionVM = { IsCineClubMember: false }; console.log(error); });

    var lazyImages = [].slice.call(document.querySelectorAll("source[data-srcset]"));
    lazyImages = lazyImages.concat([].slice.call(document.querySelectorAll("img[data-src]")));

    //Check browser support
    if ("IntersectionObserver" in window) {
      //Create lazy load observer
      var lazyImageObserver = new IntersectionObserver(function (entries, observer) {
        entries.forEach(function (entry) {
          if (entry.isIntersecting) {
            var lazyImage = entry.target;
            lazyImage.srcset = lazyImage.dataset.srcset;
            if (lazyImage.nodeName == "IMG") {
              lazyImage.src = lazyImage.dataset.src;
            }
            lazyImageObserver.unobserve(lazyImage);
          }
        });
      });

      //Add lazy load observer to images
      lazyImages.forEach(function (lazyImage) {
        lazyImageObserver.observe(lazyImage);
        var imgSrc = $(lazyImage).parents('.transparent-lazy-load').length ? "/Content/img2017/lazy_load_transparent.png" : "/Content/img2017/lazy_load_default.png";

        //Set default blank image
        if (lazyImage.nodeName == "IMG") {
          lazyImage.src = imgSrc;
        } else {
          lazyImage.srcset = imgSrc;
        }
      });
    } else {
      //Browser doesn't support observer, fallback to more reliable method
      var active = false;
      var _lazyLoad = function lazyLoad() {
        //ensure lazy load calls don't overlap on scroll/resize
        if (active === false) {
          active = true;
          //add a slight delay so scrolling is a bit smoother
          setTimeout(function () {
            lazyImages.forEach(function (lazyImage) {
              var imageBounds = lazyImage.getBoundingClientRect();
              //check if image is within the page
              //this won't check if the image is hidden like IntersectionObserver would, but it's a fallback so it'll have to do
              if (imageBounds.top <= window.innerHeight && imageBounds.bottom >= 0 && getComputedStyle(lazyImage).display !== "none") {
                lazyImage.srcset = lazyImage.dataset.srcset;
                if (lazyImage.nodeName == "IMG") {
                  lazyImage.src = lazyImage.dataset.src;
                }
                lazyImages = lazyImages.filter(function (image) {
                  return image !== lazyImage;
                });
                if (lazyImages.length === 0) {
                  document.removeEventListener("scroll", _lazyLoad);
                  window.removeEventListener("resize", _lazyLoad);
                  window.removeEventListener("orientationchange", _lazyLoad);
                }
              }
            });
            //allow lazy load listener to fire again
            active = false;
          }, 200);
        }
      };

      //Set default blank image
      lazyImages.forEach(function (lazyImage) {
        var imgSrc = $(lazyImage).parents('.transparent-lazy-load').length ? "/Content/img2017/lazy_load_transparent.png" : "/Content/img2017/lazy_load_default.png";
        if (lazyImage.nodeName == "IMG") {
          lazyImage.src = imgSrc;
        } else {
          lazyImage.srcset = imgSrc;
        }
      });
      //Add listener to events that might bring images into the window
      document.addEventListener("scroll", _lazyLoad);
      window.addEventListener("resize", _lazyLoad);
      window.addEventListener("orientationchange", _lazyLoad);
      //Initial load of images
      _lazyLoad();
    }
  });
});